const PRODUCTION_API_ENDPOINT = 'https://hierojajaakola.fi/api/';

const genUrl = (path) => {
  let apiEndpoint = PRODUCTION_API_ENDPOINT;

  // 'npm run build' will strip out this condition
  if (process.env.NODE_ENV !== 'production') {
    apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
    if (!apiEndpoint) {
      console.error('Error: environment variable $REACT_APP_API_ENDPOINT is undefined. Please set it, for example: "REACT_APP_API_ENDPOINT=http://localhost:8080/api/ npm run start"');
    }
  }

  return `${apiEndpoint}${path}`;
}

const readBody = async (response) => {
  const contentType = response.headers.get('Content-Type');
  const isJson = contentType.startsWith('application/json');
  const body = isJson ? (await response.json()) : (await response.text());
  return { body, isJson };
}

async function getUrl(path) {
  const reqOpts = { method: 'GET' };
  const request = new Request(genUrl(path, reqOpts));

  const response = await fetch(request);
  const { body, isJson } = await readBody(response);
  return {
    body,
    isJson,
    response,
  };
}

async function postUrl(path, data, opts = {}) {
  const reqOpts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  };

  const request = new Request(genUrl(path), reqOpts);
  const response = await fetch(request);
  const { body, isJson } = await readBody(response);
  return {
    body,
    isJson,
    response,
  };
}

export const cancelReservation = (id) => getUrl(`cancel.php?id=${id}`);
export const fetchAvailable = () => getUrl('available.php');
export const fetchOneEvent = (id) => getUrl(`event.php?id=${id}`);
export const reserve = (data) => postUrl('reserve.php', data);
