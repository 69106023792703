import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';

import Page from '../Page';

export default function ThanksPage() {
  const email = useSelector((state) => state.reservationApi.arg?.email);
  return (
    <Page className="pageContact">
      <Container>
        <Row className="mt-3 mb-3">
          <Col>
            <h1>Kiitos varauksesta!</h1>
            <p>
              Saat varausvahvistuksen sähköpostina osoitteeseen <strong>{email}</strong>
            </p>
            <p>
              Jos sähköpostia ei näy, tarkista että ylläoleva sähköpostiosoite on kirjoitettu oikein.
              Tarkista myös, että löytyykö viesti roskaposteista.
            </p>
            <Button onClick={() => {
              window.location.href = '/';
            }}>Palaa etusivulle</Button>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};
