import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { ROUTES, getCurrentRoute } from '../../../router';
import Footer from '../Footer';
import logo from './logo.svg';
import './styles.scss';

function NavLink({ active, children, to }) {
  return (
    <span className={`navLink ${active ? 'active' : ''}`}>
      <a href={to} className={`${active ? 'active' : ''}`}>{children}</a>
    </span>
  );
}

export default function Page({ children }) {
  const route = getCurrentRoute();
  return (
    <>
      <Container fluid className="header">
        <div className="logoContainer mb-3">
          <a href="/">
            <img src={logo} alt="Logo" id="logo" />
          </a>
        </div>

        <Row className="pt-3 pb-3 text-center">
          <Col className="text-center">
            <NavLink to="/ajanvaraus" active={route === ROUTES.RESERVATION}>
              Ajanvaraus
            </NavLink>

            <NavLink to="/yhteystiedot" active={route === ROUTES.CONTACT}>
              Yhteystiedot
            </NavLink>
          </Col>
        </Row>
      </Container>

      <div className="content">
        {children}
      </div>

      <Footer />
    </>
  );
}
