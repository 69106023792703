export const getDayName = (date) => {
  switch (date.getDay()) {
    case 1:
      return 'maanantai';
    case 2:
      return 'tiistai';
    case 3:
      return 'keskiviikko';
    case 4:
      return 'torstai';
    case 5:
      return 'perjantai';
    case 6:
      return 'lauantai';
    case 0:
      return 'sunnuntai';
    default:
      return '';
  }
}

// Returns filtered array from given times, having only times that belong
// to the same date as given date.
export const filterDayTimes = (times, date) => {
  let dayStart = new Date(date);
  dayStart.setHours(0);
  dayStart.setMinutes(0);
  dayStart.setSeconds(0);
  dayStart = dayStart.getTime();

  let dayEnd = new Date(date);
  dayEnd.setHours(23);
  dayEnd.setMinutes(59);
  dayEnd.setSeconds(59);
  dayEnd = dayEnd.getTime();

  const filtered = times.filter((time) => time >= dayStart && time <= dayEnd);
  return filtered;
};

export const formatDate = (d) => `${getDayName(d)} ${d.getDate()}.${d.getMonth() + 1}.`;

export const formatDateTime = (d) => {
  const w = getDayName(d);
  const da = d.getDate();
  const m = d.getMonth() + 1;
  const h = d.getHours();
  let min = d.getMinutes();
  if (min < 10) {
    min = '0' + min;
  }
  return `${w} ${da}.${m}. ${h}:${min}`;
};
