import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faLocationArrow, faPhone } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import Page from '../Page';

function ContactCard({ children, icon }) {
  return (
    <Card className="cardContact mt-3 mb-3">
      <Card.Body className="text-center">
        <Card.Title>
          <FontAwesomeIcon icon={icon} />
        </Card.Title>
        <Card.Text>
          {children}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default function ContactPage() {
  return (
    <Page className="pageContact">
      <Container className="pageContact">
        <Row className="mb-3">
          <Col sm={6}>
            <ContactCard icon={faPhone}>
              <a href="tel:0505283271">050 528 3271</a>
            </ContactCard>
            <ContactCard icon={faAt}>
              ansku@hierojajaakola.fi
            </ContactCard>
          </Col>

          <Col sm={6}>
            <ContactCard icon={faLocationArrow}>
              Kalevanpuisto 51<br />
              28120 Pori<br />
              Sisäänkäynti R-kioskin vieressä
            </ContactCard>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col className="text-center">
            Jos en pysty vastaamaan puheluun, laita viestiä niin otan yhteyttä!
          </Col>
        </Row>
      </Container>
    </Page>
  );
};
