import React from 'react';
import { Provider } from 'react-redux';

import AboutPage from '../pages/AboutPage';
import CancelPage from '../pages/CancelPage';
import ContactPage from '../pages/ContactPage';
import PrivacyPage from '../pages/PrivacyPage';
import ReservationPage from '../pages/ReservationPage'
import store from '../../store';
import { ROUTES, getCurrentRoute } from '../../router';

const App = () => {
  const route = getCurrentRoute();
  let page;
  if (route === ROUTES.CANCEL) {
    page = <CancelPage />;
  } else if (route === ROUTES.RESERVATION) {
    page = <ReservationPage />;
  } else if (route === ROUTES.CONTACT) {
    page = <ContactPage />;
  } else if (route === ROUTES.PRIVACY) {
    page = <PrivacyPage />;
  } else {
    page = <AboutPage />;
  }

  return (
    <Provider store={store}>
      {page}
    </Provider>
  );
};

export default App;
