import {
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';

import events from '../slices/events';
import reservationApi from '../slices/reservationApi';

const reducer = combineReducers({
  events: events.reducer,
  reservationApi: reservationApi.reducer,
});

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer,
});

export default store;
