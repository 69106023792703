import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';

import * as api from '../api';
import { PRODUCTS } from '../constants';

export const fetchAvailable = createAsyncThunk(
  'AVAILABLE/FETCH',
  async (arg, thunkAPI) => {
    const { body, isJson, response } = await api.fetchAvailable();
    return isJson && response.ok ? body : thunkAPI.rejectWithValue(body);
  },
);

const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    loading: 'idle',
    products: PRODUCTS,
  },

  reducers: {
    selectProduct(state, action) {
      state.selectedProduct = action.payload;
      delete state.selectedDate;
      delete state.selectedTime;
    },

    selectDate(state, action) {
      state.selectedDate = action.payload;
      delete state.selectedTime;
    },

    selectTime(state, action) {
      state.selectedTime = action.payload;
    },
  },

  extraReducers: {
    [fetchAvailable.pending]: (state, action) => {
      if (state.loading === 'idle') {
        delete state.errors;
        state.loading = 'pending';
      }
    },

    [fetchAvailable.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        delete state.errors;
        state.loading = 'idle';
        state.available = action.payload;
      }
    },

    [fetchAvailable.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        state.errors = action.payload;
        state.loading = 'idle';
      }
    },
  },
})

export default eventsSlice;
