import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import queryString from 'query-string';

import Page from '../Page';
import { cancelReservation, fetchOneEvent } from '../../../api';
import { MIN_TIME_LEFT_CANCELABLE } from '../../../constants';
import { formatDateTime } from '../../../services/date';

const readEventId = () => {
  const params = queryString.parse(window.location.search);
  return params.cancel;
};

const Wrapper = ({ children }) => (
  <Page>
    <Container>
      <Row className="mt-4 mb-2">
        <Col><h1>Varauksen peruminen</h1></Col>
      </Row>
      {children}
    </Container>
  </Page>
);

const TextWrapper = ({ children }) => (
  <Wrapper>
    <Row>
      <Col>{children}</Col>
    </Row>
  </Wrapper>
);

const CancelPage = () => {
  const [cancelling, setCancelling] = useState(false);
  const [status, setStatus] = useState();
  const [start, setStart] = useState();
  const id = readEventId();

  useEffect(() => {
    async function run() {
      const { body, isJson, response } = await fetchOneEvent(id);
      if (isJson) {
        if (response.ok) {
          setStart(body.start);
        } else if (response.status === 404) {
          if (body.error === 'cancelled') {
            setStatus('already-cancelled');
          } else {
            setStatus('not-found');
          }
        } else {
          setStatus('error');
        }
      }
    }
    run();
  }, [id, setStart, setStatus]);

  let canBeCancelled;
  let formatted;
  if (start) {
    const startDate = new Date(start);
    formatted = formatDateTime(startDate);
    canBeCancelled = startDate.getTime() - Date.now() > (
      MIN_TIME_LEFT_CANCELABLE * 60 * 60 * 1000
    );
  }

  const handleClick = async () => {
    setCancelling(true);
    const { isJson, response } = await cancelReservation(id);
    if (isJson && response.ok) {
      // console.debug('RESPONSE', body);
      setStatus('cancelled');
    }
  }

  if (status === 'cancelled') {
    return <TextWrapper>Varauksesi on nyt peruutettu.</TextWrapper>;
  } else if (status === 'already-cancelled') {
    return <TextWrapper>Varauksesi on jo peruutettu.</TextWrapper>;
  } else if (status === 'not-found') {
    return <TextWrapper>Varausta ei löytynyt.</TextWrapper>;
  } else if (status === 'error') {
    return <TextWrapper>Virhe. Yritä uudelleen.</TextWrapper>;
  } else if (start === undefined) {
    return <TextWrapper>Ladataan tietoja...</TextWrapper>;
  }

  return (
    <Page>
      <Container>
        <Row className="mt-4">
          <Col><h1>Varauksen peruminen</h1></Col>
        </Row>
        <Row>
          <Col>
            Sinulle on varattu aika:<br />
            <strong>{formatted}</strong>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {canBeCancelled === true && (
              <Button
                variant="danger"
                onClick={handleClick}
                disabled={cancelling}
              >
                PERUUTA VARAUS
              </Button>
            )}
            {canBeCancelled === false && (
              <div>
                Varaustasi ei voi enää perua.
                Ota yhteyttä Annetteen.
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

export default CancelPage;
