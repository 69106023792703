import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

import Product from '../Product';

function ErrorAlert() {
  return (
    <Alert variant="danger">
      <FontAwesomeIcon icon={faBan} /> Varausjärjestelmä
      ei ole tällä hetkellä käytössä. Jos haluat varata ajan, <a href="/yhteystiedot">ota yhteyttä</a> Annetteen.
    </Alert>
  );
}

export default function Catalogue() {
  const products = useSelector((state) => state.events.products);
  const ids = Object.keys(products);

  const errors = useSelector((state) => state.events.errors);
  if (errors) {
    return (
      <Row className="mt-3">
        <Col lg={{ span: 6, offset: 3}}>
          <ErrorAlert />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row className="mt-3 mb-1">
        <Col>
          <h4>1. Valitse hieronta</h4>
        </Col>
      </Row>
      <Row>
        {ids.map((id) => <Product key={id} product={products[id]} />)}
      </Row>
    </>
  );
}
