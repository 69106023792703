import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';

import { selectFreeTimes } from '../../selectors';
import { filterDayTimes } from '../../services/date';
import Day from './Day';
import Time from './Time';

const genDays = (days, offset = 0) => {
  let time = new Date();
  time.setHours(12);
  time.setMinutes(0);
  time.setSeconds(0);
  time.setMilliseconds(0);
  time = time.getTime();
  const d = [];
  for (let i = offset; i < days + offset; i++) {
    time += 24 * 60 * 60 * 1000;
    d.push(time);
  }
  return d;
};

function NoTimeAvailable() {
  return (
    <Row>
      <Col>Ei vapaita aikoja tällä hetkellä.</Col>
    </Row>
  );
}

function Times({ times }) {
  const selectedDate = useSelector((state) => state.events.selectedDate);
  if (!selectedDate) {
    return null;
  }

  const dayTimes = times[selectedDate];
  const timeComponents = dayTimes.map((time) => (
    <Time time={time} key={time} />
  ));

  return (
    <>
      <Row className="mb-1">
        <Col><h4>3. Valitse aika</h4></Col>
      </Row>
      <Row className="mb-1">
        <Col>
          {timeComponents}
        </Col>
      </Row>
    </>
  );
}

export default function TimePicker() {
  const available = useSelector((state) => state.events.available);
  const selectedId = useSelector((state) => state.events.selectedProduct);
  const product = useSelector((state) => state.events.products[selectedId]) ?? {};
  const allTimes = useSelector((state) => selectFreeTimes(state, product.id));

  const selectedDate = useSelector((state) => state.events.selectedDate);
  const selectedTime = useSelector((state) => state.events.selectedTime);

  if (!product || !selectedId) {
    return null;
  }

  if (!available || available.length === 0 || allTimes.length === 0) {
    return <NoTimeAvailable />;
  }

  // create object where key=date(string) and value=times
  const times = {};
  genDays(60, 0).forEach((date) => {
    const dayTimes = filterDayTimes(allTimes, date);
    if (dayTimes.length > 0) {
      times[date] = dayTimes;
    }
  });

  if (Object.keys(times).length === 0) {
    return <NoTimeAvailable />;
  }

  const dayComponents = Object.keys(times).map((date) => {
    return <Day date={parseInt(date)} key={date} />;
  });

  return (
    <>
      <Row className="mt-1 mb-1">
        <Col><h4>2. Valitse päivä</h4></Col>
      </Row>
      <Row className="mb-1">
        <Col>
          {dayComponents}
        </Col>
      </Row>
      {!selectedDate && (
        <Row className="mb-3">
          <Col className="mb-2">
            Jos et löydä sopivaa päivää, voit tiedustella vapaita
            aikoja <a href="/yhteystiedot">myös puhelimitse</a>.
          </Col>
        </Row>
      )}
      <Times times={times} />
      {selectedDate && !selectedTime && (
        <Row className="mb-3">
          <Col className="mb-2">
            Jos et löydä sopivaa aikaa, voit tiedustella vapaita
            aikoja <a href="/yhteystiedot">myös puhelimitse</a>.
          </Col>
        </Row>
      )}
    </>
  );
}
