import React from 'react';
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';

import Catalogue from '../../Catalogue';
import DataFetcher from '../../DataFetcher';
import Page from '../Page';
import RequestForm from '../../RequestForm';
import ThanksPage from '../ThanksPage';
import TimePicker from '../../TimePicker';

export default function ReservationPage() {
  const reserved = useSelector((state) => state.reservationApi.success === true);
  if (reserved) {
    return <ThanksPage />;
  }

  return (
    <>
      <DataFetcher />
      <Page>
        <Container>
          <Catalogue />
          <TimePicker />
          <RequestForm />
        </Container>
      </Page>
    </>
  );
};
