const EVENT_INTERVAL = 15 * 60 * 1000;

// reserve extra minutes after reservation for preparation
// const RESERVATION_MARGIN = 30;

export function selectFreeTimes(state, productId) {
  const duration = (state.events.products[productId]?.duration ?? 15) * 60 * 1000;
  const slots = state.events.available ?? [];
  const startTimes = [];
  for (let i = 0; i < slots.length; i++) {
    let start = Date.parse(slots[i].start ?? 0);
    const end = Date.parse(slots[i].end ?? 0);
    while (end - start >= duration) {
      startTimes.push(start);
      start += EVENT_INTERVAL;
    }
  }
  return startTimes;
}
