import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Page from '../Page';

export default function PrivacyPage() {
  return (
    <Page>
      <Container>
        <Row className="mt-3 mb-3">
          <Col>
            <h1>Tietosuojaseloste</h1>
            <p>
              Koulutettu hieroja Annette Jaakola arvostaa yksityisyyttäsi
              ja on sitoutunut suojaamaan henkilötietojasi. Tämä tietosuojaseloste
              kertoo sinulle, miksi ja mitä tietoja kerätään, miten niitä käsitellään
              sekä mitä oikeuksia ja vaikuttamismahdollisuuksia sinulla on tietojesi
              suhteen. Koulutettu hieroja, terveydenhuollon ammattilaisena ja yrittäjänä,
              kerää potilaistaan hoidon ja asiakassuhteen kannalta olennaiset tiedot.
              Ammatinharjoittaja on vastuussa keräämistään potilastiedoista,
              niiden säilyttämisestä ja suojaamisesta.
              Koulutettu hieroja on vaitiolovelvollinen ja potilastiedot ovat
              lain mukaan salassa pidettäviä.
            </p>
            <p>
              Tämä on henkilötietolain (10 ja 24 §) ja EU:n
              yleisen tietosuoja-asetuksen (GDPR) mukainen rekisteri- ja tietosuojaseloste.
            </p>
            <h5>Rekisterinpitäjä</h5>
            <p>
              Tmi Annette Jaakola, Varvinkatu 21 as 1, 28100, Pori<br/>
              Y-tunnus 2955837-4
            </p>
            <h5>Rekisteristä vastaava yhteyshenkilö</h5>
            <p>
              Annette Jaakola, ansku@hierojajaakola.fi, p. 050 528 3271
            </p>
            <h5>Rekisterin nimi</h5>
            <p>Tmi Annette Jaakolan asiakasrekisteri</p>

            <h5>Oikeusperuste ja henkilötietojen käsittelyn tarkoitus</h5>
            <p>
              EU:n yleisen tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen
              käsittelylle on rekisterinpitäjän oikeutettu etu (asiakassuhde).
              Kerättyjä tietoja käytetään ensisijaisesti hierontaan mahdollisesti
              vaikuttavan asiakkaan terveydentilan kartoittamiseen ja asiakkaan
              hoidon suunnitteluun. Henkilötietojen käsittelyn tarkoitus on myös
              yhteydenpito asiakkaisiin (asiakkaiden tiedottaminen ja muistuttaminen
              Tmi Annette Jaakolan palveluista).
            </p>
            <p>
              Potilasrekisterin ylläpitäminen on lainmukainen velvoite ja sen tiedot
              perustuvat seuraaviin lakeihin ja asetuksiin:
            </p>
            <ul>
              <li>laki ja asetus terveydenhuollon ammattihenkilöistä</li>
              <li>laki potilaan asemasta ja oikeuksista</li>
              <li>terveydenhuoltolaki</li>
              <li>sosiaali- ja terveysministeriön asetus potilasasiakirjoista</li>
              <li>laki sosiaali- ja terveydenhuollon asiakastietojen sähköisestä käsittelystä</li>
            </ul>

            <h5>Säännönmukaiset tietolähteet</h5>
            <p>Tiedot saadaan asiakkaalta sähköisen ajanvarauksen,
            sähköpostin tai puhelimen välityksellä tiedustelujen yhteydessä.
            Lisäksi asiakas täyttää esitietolomakkeen tullessaan ensimmäistä
            kertaa hoitoon.
            </p>

            <h5>Rekisterin tietosisältö</h5>
            <p>
              Esitietolomakkeella kerättäviä tietoja ovat asiakkaan nimi,
              henkilötunnus, postiosoite, puhelinnumero, sähköpostiosoite,
              harrastukset, työ, terveydentila, sairaudet, vammat, säännölliset
              lääkitykset, käyntitiedot ja potilaan hoidon kannalta välttämättömiä
              tietoja. Arkaluonteisia tietoja ei kirjata, näitä ovat mm.
              etninen alkuperä, yhteiskunnallinen, poliittinen, uskonnollinen
              vakaumus, ammattiliittoon kuuluminen jne.
            </p>
            <p>
              Ajanvarauksen yhteydessä talteen jäävät asiakkaan nimi ja
              sähköpostiosoite sekä vapaaehtoisesti annettu puhelinnumero
              ja mahdolliset lisätiedot.
            </p>
            <p>
              Tietoja säilytetään niin kauan kuin se on asiakassuhteen hoitamisen
              kannalta tarpeellista.
            </p>

            <h5>Tietojen säännönmukaiset luovutukset ja tietojen siirto EU:n tai ETA:n ulkopuolelle</h5>
            <p>
              Tmi Annette Jaakolan asiakasrekisteri on luottamuksellinen eikä tietoja luovuteta muille tahoille.
            </p>

            <h5>Rekisterin suojauksen perusteet</h5>
            <p>
              Rekisterin käsittelyssä noudatetaan huolellisuutta ja tietojärjestelmien
              avulla käsiteltävät tiedot suojataan asianmukaisesti. Kun rekisteritietoja
              säilytetään Internet-palvelimilla, niiden laitteiston fyysisestä
              ja digitaalisesta tietoturvasta huolehditaan asiaankuuluvasti.
              Rekisterinpitäjä huolehtii siitä, että tallennettuja tietoja sekä
              palvelimien käyttöoikeuksia ja muita henkilötietojen turvallisuuden
              kannalta kriittisiä tietoja käsitellään luottamuksellisesti.
            </p>

            <p>
              Paperidokumentteja pidetään lukituissa ja valvotuissa tiloissa.
              Sähköisesti käsiteltäviin tietoihin on pääsy vain siihen oikeutetun
              työntekijän henkilökohtaisella käyttäjätunnuksella ja salasanalla.
            </p>

            <h5>Muut henkilötietojen käsittelyyn liittyvät oikeudet</h5>
            <p>
              Rekisterissä olevalla henkilöllä on oikeus pyytää häntä
              koskevien henkilötietojen poistamiseen rekisteristä ("oikeus tulla unohdetuksi").
              Pyyntö tulee lähettää kirjallisesti rekisterinpitäjälle.
              Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan
              henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n
              tietosuoja-asetuksessa säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).
            </p>

            <h5>Tietoa evästeistä</h5>
            <p>
            Eväste on pieni tekstitiedosto, joka tallennetaan käyttäjän tietokoneelle,
            kun hän vierailee sivustolla. Evästeet eivät sisällä henkilötietoja
            eikä niiden avulla voida suorittaa ohjelmia tai tallentaa viruksia
            käyttäjän tietokoneelle.</p>
            <p>Tällä sivustolla käytetään evästeitä, jotta voidaan seurata
            sivuston käyttäjämääriä sekä tarjota parempaa käyttökokemusta
            asiakkaille.</p>
            <p>
              Jos haluat estää evästeet, muuta selaimesi evästeasetuksia.
              Jos et käytä evästeitä, sivusto saattaa toimia puutteellisesti.
            </p>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};
