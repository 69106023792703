import React from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';

import eventsSlice from '../../slices/events';
const { selectTime } = eventsSlice.actions;

export default function Time({ time }) {
  const dispatch = useDispatch();
  var d = new Date(time);
  const mins = d.getMinutes();
  var text = `${d.getHours()}:${mins < 10 ? '0' : ''}${mins}`;

  const active = useSelector((state) => state.events.selectedTime === time);
  const handleSelect = () => {
    dispatch(selectTime(time));
  };
  return (
    <Button
      onClick={handleSelect}
      className="mb-3 mr-3"
      variant={active ? 'primary' : 'outline-primary'}
    >
      {text}
    </Button>
  );
}
