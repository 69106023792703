import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchAvailable } from '../../slices/events';

function useDataFetch() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAvailable());
  });
}


export default function DataFetcher() {
  useDataFetch();
  // render nothing
  return null;
}
