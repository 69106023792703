import React from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from '../../services/date';
import eventsSlice from '../../slices/events';
const { selectDate } = eventsSlice.actions;

export default function Day({ date }) {
  const dispatch = useDispatch();
  const active = useSelector((state) => state.events.selectedDate === date);
  const title = formatDate(new Date(date));

  return (
    <Button
      onClick={() => dispatch(selectDate(date))}
      className="mb-3 mr-3"
      variant={active ? 'primary' : 'outline-primary'}
    >
      {title}
    </Button>
  );
}
