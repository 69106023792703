import queryString from 'query-string';

export const ROUTES = {
  ABOUT: 'about',
  RESERVATION: 'reservation',
  CONTACT: 'contact',
  CANCEL: 'cancel',
  PRIVACY: 'privacy',
};

export const getCurrentRoute = () => {
  const path = window.location.pathname;
  if (path.startsWith('/yhteystiedot')) {
    return ROUTES.CONTACT;
  }

  const parsed = queryString.parse(window.location.search);
  if (parsed.cancel) {
    return 'cancel';
  }

  if (path.startsWith('/ajanvaraus')) {
    return ROUTES.RESERVATION;
  }

  if (path.startsWith('/tietosuojaseloste')) {
    return ROUTES.PRIVACY;
  }

  return ROUTES.ABOUT;
};
