// Minimum time (in hours) left for a reservation when it's still cancelable.
export const MIN_TIME_LEFT_CANCELABLE = 24;

export const PRODUCTS = {
  1: {
    id: 1,
    name: 'Hieronta 30 min',
    duration: 30,
    price: 30,
  },
  2: {
    id: 2,
    name: 'Hieronta 45 min',
    duration: 45,
    price: 45,
  },
  3: {
    id: 3,
    name: 'Hieronta 60 min',
    duration: 60,
    price: 55,
  },
  4: {
    id: 4,
    name: 'Hieronta 90 min',
    duration: 90,
    price: 75,
  },
};
