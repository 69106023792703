import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';

import { makeReservation } from '../../slices/reservationApi';

export default function RequestForm() {
  const dispatch = useDispatch();
  const productId = useSelector((state) => state.events.selectedProduct);
  const time = useSelector((state) => state.events.selectedTime);
  const pending = useSelector((state) => state.reservationApi.loading === 'pending');
  const formRef = useRef();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [comment, setComment] = useState('');

  if (!productId || !time) {
    return null;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const data = {
      name,
      email,
      phone,
      comment,
      productId,
      time,
    };

    // make request to server
    dispatch(makeReservation(data));
  };

  return (
    <Form onSubmit={handleSubmit} className="mt-2 mb-3" ref={formRef}>
      <Form.Group>
        <h4>4. Täytä tiedot</h4>
      </Form.Group>
      <Form.Group controlId="formName">
        <Form.Label>Nimi*</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Etunimi Sukunimi"
          value={name}
          onChange={(evt) => setName(evt.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="formEmail">
        <Form.Label>Sähköposti*</Form.Label>
        <Form.Control
          required
          type="email"
          placeholder="nimi@sahkoposti.com"
          value={email}
          onChange={(evt) => setEmail(evt.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="formPhone">
        <Form.Label>Puhelin</Form.Label>
        <Form.Control
          type="tel"
          placeholder="0501234567"
          value={phone}
          onChange={(evt) => setPhone(evt.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="formComment">
        <Form.Label>Lisätietoja</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Mahdollisia varaukseen liittyviä lisätietoja"
          rows={3}
          value={comment}
          onChange={(evt) => setComment(evt.target.value)}
        />
      </Form.Group>

      <Button
        variant="primary"
        type="submit"
        size="lg"
        className="mb-3"
        disabled={pending}
      >
        TEE VARAUS
      </Button>
    </Form>
  );
}
