import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';

import eventsSlice from '../../slices/events';
const { selectProduct } = eventsSlice.actions;

export default function Product({ product }) {
  const dispatch = useDispatch();
  const onSelect = () => dispatch(selectProduct(product.id));
  const active = useSelector((state) => state.events.selectedProduct === product.id);

  return (
    <Col className="col-6 col-md-3 mb-3">
      <Button variant={active ? 'primary' : 'outline-primary'} onClick={onSelect}>
        {product.duration} min &ndash; {product.price} €
      </Button>
    </Col>
  );
}
