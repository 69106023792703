import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';

import * as api from '../api';

export const makeReservation = createAsyncThunk(
  'RESERVATION/MAKE',
  async (arg, thunkAPI) => {
    const { body, isJson, response } = await api.reserve(arg);
    return isJson && response.ok ? body : thunkAPI.rejectWithValue(body);
  },
);

const slice = createSlice({
  name: 'reservationsApi',
  initialState: {
    errors: {},
    loading: 'idle',
  },

  reducers: {},

  extraReducers: {
    [makeReservation.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.errors = {};
        state.loading = 'pending';
        state.arg = action.meta?.arg;
      }
    },

    [makeReservation.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.errors = {};
        state.loading = 'idle';
        state.success = true;
      }
    },

    [makeReservation.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        state.errors = action.payload;
        state.loading = 'idle';
      }
    },
  },
})

export default slice;
